import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Body, IconButton } from "~/ui-library";

type PaginationSelectProps = {
  onNextPage: () => void;
  onPreviousPage: () => void;
  /** index 1 */
  selectedPage: number;
  totalPages: number;
  totalResults: number;
  startingIndex: number;
  endingIndex: number;
};

export const PaginationSelect: React.FC<PaginationSelectProps> = ({
  onNextPage,
  onPreviousPage,
  selectedPage,
  totalPages,
  totalResults,
  startingIndex,
  endingIndex,
}) => (
  <nav className="flex items-center justify-end">
    <Body size="small" className="mr-m text-gray-700">
      {startingIndex} - {endingIndex} of {totalResults}
    </Body>
    <div className="mr-xs">
      <IconButton
        aria-label="View previous page"
        disabled={selectedPage === 1}
        onClick={onPreviousPage}
        size="small"
        SvgIcon={ChevronLeftIcon}
        variant="subtle"
      />
    </div>
    <div>
      <IconButton
        aria-label="View next page"
        disabled={selectedPage === totalPages}
        onClick={onNextPage}
        size="small"
        SvgIcon={ChevronRightIcon}
        variant="subtle"
      />
    </div>
  </nav>
);
