/* eslint-disable @typescript-eslint/naming-convention */
import useSWRInfinite from "swr/infinite";
import { SearchyResponse } from "~/types/searchy/SearchyResponse";
import { fetchSearchyResults } from "~/lib/client/endilApiCalls";
import { SearchyApiRequest } from "~/pages/api/search.page";
import { useSession } from "next-auth/react";

export type UseSearchyRequest = SearchyApiRequest["query"];

type HookOptions = {
  enableQuery: boolean;
};

export interface UseSearchyResultResponse {
  /** Loading the first set of data on the page */
  isLoading: boolean;
  /** Loading additional page data, does not conflict with the first load */
  isLoadingMoreResults: boolean;
  results: SearchyResponse["results"];
  totalResults: number;
  formattedTotalResults: string;
  loadNextPage: () => void;
}

const numberFormatRegex = /\B(?=(\d{3})+(?!\d))/g;

export const useSearchyResults = (
  request: UseSearchyRequest,
  { enableQuery }: HookOptions = { enableQuery: true }
) => {
  const { status } = useSession();
  const {
    data: pages,
    error,
    setSize,
    size,
    isLoading,
    isValidating: isLoadingMoreResults,
  } = useSWRInfinite<SearchyResponse>(
    (idx, previousPageData: SearchyResponse) => {
      if (status === "loading" || !enableQuery) {
        return null;
      }
      const {
        page_size,
        query,
        content_kind,
        order,
        producer_author_id,
        producer_category_id,
        producer_tag_id,
        producer_team,
        producer_type,
      } = request;
      if (previousPageData) {
        const { pagination_token } = previousPageData;
        return {
          page_size,
          query,
          content_kind,
          order,
          producer_author_id,
          producer_category_id,
          producer_tag_id,
          producer_team,
          producer_type,
          pagination_token,
        };
      }

      return {
        page_size,
        query,
        content_kind,
        order,
        producer_author_id,
        producer_category_id,
        producer_tag_id,
        producer_team,
        producer_type,
      } satisfies SearchyApiRequest["query"];
    },
    async (args: SearchyApiRequest["query"]) => {
      const {
        page_size,
        query,
        content_kind,
        order,
        producer_author_id,
        producer_category_id,
        producer_tag_id,
        producer_team,
        producer_type,
        pagination_token,
      } = args;
      const response = await fetchSearchyResults({
        page_size,
        query,
        content_kind,
        order,
        producer_author_id,
        producer_category_id,
        producer_tag_id,
        producer_team,
        producer_type,
        pagination_token,
      });

      return response.data;
    },
    {
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      keepPreviousData: Boolean(request.query),
    }
  );

  if (error) {
    throw error;
  }

  const results = pages?.map((pageData) => pageData.results).flat() ?? [];
  const totalResults = pages?.[0].total_hits ?? 0;
  const formattedTotalResults = totalResults
    .toString()
    .replace(numberFormatRegex, ",");

  const loadNextPage = () => {
    setSize(size + 1);
  };

  const loadPreviousPage = () => {
    setSize(size - 1);
  };

  return {
    isLoading: isLoading || status === "loading",
    isLoadingMoreResults: !isLoading && isLoadingMoreResults,
    results,
    totalResults,
    formattedTotalResults,
    loadNextPage,
    loadPreviousPage,
    currentPage: size,
  };
};
