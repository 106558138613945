import { useEffect } from "react";

export const useHideBodyOverflow = (shouldHide: boolean) =>
  useEffect(() => {
    if (shouldHide) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [shouldHide]);
