import { cva } from "cva";
import { SkeletonLoader } from "~/ui-library";

export type ContentItemWithImageSkeletonProps = {
  imagePosition?: "left" | "top" | "right";
  asHero?: boolean;
  noImageOnShrink?: boolean;
};

const wrapperStyles = cva(["flex", "flex-1", "flex-col", "gap-y-s"], {
  variants: {
    imagePosition: {
      left: ["md:flex-row", "md:gap-x-m", "md:gap-y-0"],
      right: ["md:flex-row-reverse", "md:gap-x-m", "md:gap-y-0"],
      top: [],
    },
    asHero: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      asHero: true,
      imagePosition: "top",
      className: [
        "md:flex-row",
        "md:gap-x-m",
        "md:gap-y-0",
        "lg:flex-col",
        "lg:gap-y-s",
      ],
    },
  ],
});

const imageStyles = cva(["relative", "overflow-hidden", "aspect-16/9"], {
  variants: {
    imagePosition: {
      left: ["min-w-[250px]"],
      top: [],
      right: [],
    },
    asHero: {
      true: "",
      false: "",
    },
    noImageOnShrink: {
      true: "hidden lg:block",
      false: "",
    },
  },
  compoundVariants: [
    {
      asHero: true,
      imagePosition: "top",
      className: "!block sm:min-w-[250px]",
    },
  ],
});

export const ContentItemWithImageSkeleton: React.FC<
  ContentItemWithImageSkeletonProps
> = ({ imagePosition = "left", asHero = false, noImageOnShrink = false }) => (
  <div
    className={wrapperStyles({ imagePosition, asHero })}
    data-testid="content-item-with-image-skeleton"
  >
    <div className={imageStyles({ imagePosition, asHero, noImageOnShrink })}>
      <SkeletonLoader count={1} height="100%" width="100%" className="block" />
    </div>
    <div className="w-[100%]">
      <SkeletonLoader count={4} width="100%" height="1.125rem" />
    </div>
  </div>
);
