import { cx } from "cva";
import { Fragment, useState } from "react";
import FocusLock from "react-focus-lock";
import Skeleton from "react-loading-skeleton";
import { useAnalytics } from "~/components/Analytics";
import { ContentItemWithImage } from "~/components/ContentItemWithImage";
import { ContentItemWithImageSkeleton } from "~/components/ContentItemWithImageSkeleton";
import { Divider } from "~/components/Divider";
import { GenericSearchEvents } from "~/components/GenericSearch/GenericSearchEvents";
import { PaginationSelect } from "~/components/PaginationSelect/PaginationSelect";
import { SearchInput } from "~/components/SearchInput";
import { useHideBodyOverflow } from "~/lib/client/useHideBodyOverflow";
import { useSearchyResults } from "~/lib/client/useSearchyResults";
import { heDecodeOrDefault } from "~/lib/shared/html-utils";
import { ReportHorizontal } from "~/types/asgard/ReportLibrary";
import { SearchyOrder } from "~/types/searchy/SearchyOrder";
import {
  Body,
  Button,
  Chip,
  ChipLink,
  Headline,
  Icon,
  IconButton,
  Link,
} from "~/ui-library";
import { Flyout } from "~/ui-library/components/Flyout/Flyout";
import { Option, OptionData, Select } from "~/ui-library/components/Select";
import { XMarkIcon } from "~/ui-library/icons";

/** server-compatible props that work with the apiToGenericSearchProps function */
export type ServerGenericSearchProps = {
  categoryOptions: OptionData[];
  contentTypeOptions: OptionData[];
  topicOptions: OptionData[];
  pageSize: number;
  sectionTitle: string;
};
export type GenericSearchProps = Omit<
  ServerGenericSearchProps,
  "sectionTitle"
> & {
  /**
   * Whether or not to make the query. by default will run after state is
   * initialized from the query params
   */
  enableQuery: boolean;
  selectedTopic: OptionData | null;
  onTopicSelect: (selected: OptionData | null) => void;

  selectedCategory: OptionData | null;
  onCategorySelect: (selected: OptionData | null) => void;

  selectedContentType: OptionData | null;
  onContentTypeSelect: (selected: OptionData | null) => void;

  selectedSort: OptionData | null;
  sortOptions: OptionData[];
  onSortSelect: (selected: OptionData) => void;

  searchValue: string;
  setSearchValue: (val: string) => void;

  sectionTitle: React.ReactNode;

  className?: string;
  selectedHorizontal?: ReportHorizontal;
};

export const defaultSortOptions: OptionData[] = [
  {
    label: "Relevance",
    id: "",
  },
  {
    label: "Most Recent",
    id: SearchyOrder.CREATED_AT_DESC,
  },
  {
    label: "Least Recent",
    id: SearchyOrder.CREATED_AT_ASC,
  },
];

export const GenericSearch: React.FC<GenericSearchProps> = ({
  className,
  enableQuery,
  searchValue,
  setSearchValue,
  topicOptions,
  onTopicSelect,
  selectedTopic,
  onSortSelect,
  selectedSort,
  sortOptions,
  onCategorySelect,
  selectedCategory,
  onContentTypeSelect,
  selectedContentType,
  contentTypeOptions,
  categoryOptions,
  pageSize,
  sectionTitle,
  selectedHorizontal,
}) => {
  const {
    currentPage,
    results: allResults,
    totalResults,
    formattedTotalResults,
    loadNextPage,
    loadPreviousPage,
    isLoading: isLoadingReports,
    isLoadingMoreResults,
  } = useSearchyResults(
    {
      content_kind: heDecodeOrDefault(selectedContentType?.id),
      page_size: pageSize,
      order: selectedSort?.id.toString() as SearchyOrder,
      producer_tag_id: selectedTopic?.id.toString(),
      producer_team: selectedHorizontal,
      producer_category_id: selectedCategory?.id.toString(),
      query: searchValue,
    },
    { enableQuery }
  );

  const { analyticsEnhancedTrack } = useAnalytics();
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isContentTypeOpen, setIsContentTypeOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  useHideBodyOverflow(isCategoryOpen || isContentTypeOpen || isSortOpen);

  const onNextPage = () => {
    analyticsEnhancedTrack(GenericSearchEvents.PageNext);
    loadNextPage();
  };

  const onPreviousPage = () => {
    analyticsEnhancedTrack(GenericSearchEvents.PagePrevious);
    loadPreviousPage();
  };

  const totalPages = Math.ceil(totalResults / pageSize);
  const pageIndex = currentPage - 1;
  const startIndex = Math.max(pageIndex * pageSize, 0);
  const endIndex = startIndex + pageSize - 1;

  const results = allResults.slice(startIndex, endIndex + 1);
  const possibleDisplayIndex = startIndex + 1;
  const endDisplayIndex = startIndex + results.length;

  const startDisplayIndex = Math.min(possibleDisplayIndex, endDisplayIndex);

  const sortControls = (
    <Select
      selectClassName="min-w-[150px]"
      selected={selectedSort}
      onChange={(val) => {
        onSortSelect(val);
        setIsSortOpen(false);
        analyticsEnhancedTrack(GenericSearchEvents.SortOrderSelected, {
          sortOrder: val.label,
        });
      }}
    >
      {sortOptions.map((option) => (
        <Option key={option.id} value={option}>
          {option.label}
        </Option>
      ))}
    </Select>
  );

  const filterControls = (
    <>
      {!!categoryOptions?.length && (
        <div className="mb-s flex flex-col gap-xxs">
          <Body bold size="small">
            Vertical
          </Body>

          <Select
            data-testid="category-select"
            placeholder="All"
            selectClassName="w-full"
            selected={selectedCategory}
            onChange={(val) => {
              onCategorySelect(val.id ? val : null);
              setIsCategoryOpen(false);
              analyticsEnhancedTrack(GenericSearchEvents.CategorySelected, {
                categoryName: val?.label,
              });
            }}
          >
            {categoryOptions.map((option) => (
              <Option key={option.id} value={option}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      )}

      {!!contentTypeOptions?.length && (
        <div className="mb-s flex flex-col gap-xxs">
          <Body bold size="small">
            Content Type
          </Body>

          <Select
            data-testid="content-type-select"
            placeholder="All"
            selectClassName="w-full"
            selected={selectedContentType}
            onChange={(val) => {
              onContentTypeSelect(val.id ? val : null);
              setIsContentTypeOpen(false);
              analyticsEnhancedTrack(GenericSearchEvents.ContentTypeSelected, {
                contentTypeName: val?.label,
              });
            }}
          >
            {contentTypeOptions.map((option) => (
              <Option key={option.id} value={option}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      )}

      {!!topicOptions.length && (
        <>
          <Body bold size="small">
            Topics
          </Body>

          {topicOptions.map((topic) => (
            <Body
              as={Link}
              bold={selectedTopic?.id === topic.id}
              data-testid={
                selectedTopic?.id === topic.id ? "selected-topic" : undefined
              }
              key={topic.id}
              href="#"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                if (selectedTopic?.id === topic.id) {
                  onTopicSelect(null);
                  setIsCategoryOpen(false);
                  return;
                }

                onTopicSelect(topic);
                setIsCategoryOpen(false);
                analyticsEnhancedTrack(GenericSearchEvents.TagSelected, {
                  tagName: topic.label,
                });
              }}
            >
              {topic.label}
            </Body>
          ))}
        </>
      )}
    </>
  );

  const searchControl = (
    <div className="mb-s flex flex-col gap-xxs">
      <Body bold size="small">
        Search
      </Body>

      <SearchInput
        value={searchValue}
        showClearButton={!!searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onClear={() => setSearchValue("")}
        placeholder='Search a keyword or phrase like "gen z"'
      />
    </div>
  );

  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);

  if (isFirstTimeLoading && !isLoadingReports) {
    setIsFirstTimeLoading(false);
  }

  const isLoading = isLoadingReports || isLoadingMoreResults;

  const loadingSkeleton =
    !isFirstTimeLoading && isLoading
      ? Array(pageSize)
          .fill(null)
          .map((value, index) => (
            <Fragment key={index}>
              <ContentItemWithImageSkeleton imagePosition="left" />
            </Fragment>
          ))
      : null;

  return (
    <div className={cx("mx-auto flex w-full flex-col", className)}>
      <div
        className="flex w-full scroll-mt-l flex-col gap-s md:flex-row md:items-center md:justify-between md:gap-0"
        id="search"
      >
        {!!sectionTitle && <div className="mb-l">{sectionTitle}</div>}
      </div>

      <div className="flex gap-s md:hidden">
        <Button
          aria-label="Open the filter menu"
          fullWidth
          onClick={() => setIsCategoryOpen(true)}
        >
          Filters
        </Button>
        <Button
          aria-label="Open the sort menu"
          fullWidth
          onClick={() => setIsSortOpen(true)}
        >
          Sort
        </Button>

        <Flyout
          openFromDirection="bottom"
          fillScreen={false}
          isOpen={isSortOpen}
          closeOnOverlayClick
          overlayClassName="sort-overlay"
          onClose={() => setIsSortOpen(false)}
        >
          <FocusLock disabled={!isSortOpen}>
            <div
              data-testid="sort-flyout-contents"
              className="flex flex-col border-1 border-gray-300"
            >
              <div className="flex w-full items-center justify-between px-s py-xs">
                <Headline blockCase level={6} as="div">
                  Sort By
                </Headline>

                <IconButton
                  aria-label="Close the sort menu"
                  onClick={() => setIsSortOpen(false)}
                  size="default"
                  SvgIcon={XMarkIcon}
                  variant="subtle"
                />
              </div>

              <hr />

              <div className="flex h-[225px] w-full flex-col gap-xs px-s py-l">
                {sortControls}
              </div>
            </div>
          </FocusLock>
        </Flyout>

        <Flyout
          openFromDirection="bottom"
          fillScreen={false}
          isOpen={isCategoryOpen}
          closeOnOverlayClick
          overlayClassName="filter-overlay"
          onClose={() => setIsCategoryOpen(false)}
        >
          <FocusLock disabled={!isCategoryOpen}>
            <div
              data-testid="filter-flyout-contents"
              className="flex flex-col border-1 border-gray-300"
            >
              <div className="flex w-full items-center justify-between px-s py-xs">
                <Headline blockCase level={6} as="div">
                  Filters
                </Headline>

                <IconButton
                  aria-label="Close the filter menu"
                  onClick={() => setIsCategoryOpen(false)}
                  size="default"
                  SvgIcon={XMarkIcon}
                  variant="subtle"
                />
              </div>

              <hr />

              <div className="flex w-full flex-col gap-xs px-s py-l">
                {filterControls}
              </div>
            </div>
          </FocusLock>
        </Flyout>
      </div>

      <div className="grid grid-cols-1 gap-l md:grid-cols-[250px,auto]">
        <div className="hidden w-full flex-col gap-xs pl-xxs pt-l md:flex">
          {filterControls}
        </div>

        <div className="flex flex-col gap-xs pt-l md:border-l-1 md:pl-l">
          {searchControl}
          <div className="flex items-center justify-between">
            {!isLoading ? (
              <div>
                {startDisplayIndex} - {endDisplayIndex} of{" "}
                {formattedTotalResults} results
              </div>
            ) : (
              <div>
                <Skeleton />
              </div>
            )}

            <div className="hidden items-center md:flex">
              <div className="mr-xs whitespace-nowrap">Sort by</div>
              {sortControls}
            </div>
          </div>
          <div className="flex flex-wrap gap-s py-s md:hidden">
            {!!selectedContentType && (
              <ChipLink
                appearance="default"
                className="flex items-center px-xs py-xxs"
                href="#"
                onClick={(e) => {
                  onContentTypeSelect(null);
                  e.preventDefault();
                }}
              >
                Content Type : {selectedContentType.label}
                {!!contentTypeOptions.length && (
                  <Icon size="small" SvgIcon={XMarkIcon} className="ml-xs" />
                )}
              </ChipLink>
            )}

            {selectedTopic ? (
              <ChipLink
                appearance="default"
                className="flex items-center px-xs py-xxs"
                href="#"
                onClick={(e) => {
                  onTopicSelect(null);
                  e.preventDefault();
                }}
              >
                Topic : {selectedTopic.label}
                <Icon size="small" SvgIcon={XMarkIcon} className="ml-xs" />
              </ChipLink>
            ) : null}

            {selectedCategory ? (
              <ChipLink
                appearance="default"
                className="flex items-center"
                href="#"
                onClick={(e) => {
                  onCategorySelect(null);
                  e.preventDefault();
                }}
                size="small"
              >
                Vertical : {selectedCategory.label}
                <Icon size="small" SvgIcon={XMarkIcon} className="ml-xs" />
              </ChipLink>
            ) : null}

            {selectedSort ? (
              <Chip appearance="default">Sort By: {selectedSort.label}</Chip>
            ) : null}
          </div>
          <Divider containerClassName="md:hidden" />
          {!isLoading && !results.length ? (
            <Headline as="div" level={1} className="py-l text-gray-800">
              No Results Found
            </Headline>
          ) : null}

          {loadingSkeleton}

          {results.map(
            (
              {
                entity: { image_url, label, created_at, custom_metadata, url },
              },
              idx
            ) => (
              <div key={idx} data-testid="search-results">
                <div className="flex w-full items-center gap-m py-s">
                  <ContentItemWithImage
                    imageSrc={image_url!}
                    headline={heDecodeOrDefault(label)}
                    date={created_at}
                    eyebrow={custom_metadata?.primaryTag?.name}
                    footer={heDecodeOrDefault(
                      custom_metadata?.primaryCategory?.name
                    )}
                    headlineHref={url}
                    isChartPack={custom_metadata?.isChartPack}
                    onHeadlineClick={() => {
                      analyticsEnhancedTrack(
                        GenericSearchEvents.ReportClicked,
                        {
                          reportName: label,
                        }
                      );
                    }}
                  />
                </div>
                <Divider />
              </div>
            )
          )}
          <PaginationSelect
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            selectedPage={currentPage}
            totalPages={totalPages}
            totalResults={totalResults}
            startingIndex={startDisplayIndex}
            endingIndex={endDisplayIndex}
          />
        </div>
      </div>
    </div>
  );
};
